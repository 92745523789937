var RevosliderInit = function () {

    var rev;

    var resumeSlider = function() {
        rev.revresume();
    };

    var pauseSlider = function() {
        rev.revpause();
    };

    return {
        initRevoSlider: function () {

                  rev = jQuery('.fullwidthabnner').show().revolution({
                      delay:2000,
                      startheight:417,
                      startwidth:1150,

                      hideThumbs:10,

                      thumbWidth:100,                         // Thumb With and Height and Amount (only if navigation Tyope set to thumb !)
                      thumbHeight:50,
                      thumbAmount:5,

                      navigationType:"bullet",                // bullet, thumb, none
                      navigationArrows:"solo",                // nexttobullets, solo (old name verticalcentered), none

                      navigationStyle:"round",                // round,square,navbar,round-old,square-old,navbar-old, or any from the list in the docu (choose between 50+ different item), custom

                      navigationHAlign:"center",              // Vertical Align top,center,bottom
                      navigationVAlign:"bottom",              // Horizontal Align left,center,right
                      navigationHOffset:0,
                      navigationVOffset:20,

                      soloArrowLeftHalign:"left",
                      soloArrowLeftValign:"center",
                      soloArrowLeftHOffset:20,
                      soloArrowLeftVOffset:0,

                      soloArrowRightHalign:"right",
                      soloArrowRightValign:"center",
                      soloArrowRightHOffset:20,
                      soloArrowRightVOffset:0,

                      touchenabled:"on",                      // Enable Swipe Function : on/off
                      onHoverStop:"on",                       // Stop Banner Timet at Hover on Slide on/off

                      stopAtSlide:-1,
                      stopAfterLoops:-1,

                      hideCaptionAtLimit:0,         // It Defines if a caption should be shown under a Screen Resolution ( Basod on The Width of Browser)
                      hideAllCaptionAtLilmit:0,       // Hide all The Captions if Width of Browser is less then this value
                      hideSliderAtLimit:0,          // Hide the whole slider, and stop also functions if Width of Browser is less than this value

                      shadow:1,                               //0 = no Shadow, 1,2,3 = 3 Different Art of Shadows  (No Shadow in Fullwidth Version !)
                      fullWidth:"on"                          // Turns On or Off the Fullwidth Image Centering in FullWidth Modus
                  });

            //bind html5 video event if ended then resume slider
            document.getElementById('sliderVideo1').addEventListener('ended',resumeSlider,false);
            //bind html5 video event if pause then resume slider
            document.getElementById('sliderVideo1').addEventListener('pause',resumeSlider,false);
            //bind html5 video event if played then stop slider
            document.getElementById('sliderVideo1').addEventListener('play',pauseSlider,false);
            //bind html5 video event if playing then stop slider
            document.getElementById('sliderVideo1').addEventListener('playing',pauseSlider,false);

        }
    };

}();